<template>
  <div>
    <div class="dashboard-summary">
      <div class="total px-2 px-sm-5 py-3 d-flex justify-content-between flex-wrap">
        <WorkSummary class="mb-3" value="0.00 USD" title="Total Earnings" />
        <WorkSummary class="mb-3" value="0 Hour" title="Total hours worked" />
      </div>
    </div>
    <div class="dashboard-content">
      <div class="cards flex d-flex justify-content-between my-4 flex-wrap">
        <div class="upskill-card mb-3 mb-md-0">
          <UpdateSkills @updated="$router.push('/skills')" />
        </div>
        <div class="status-cards d-flex flex-column">
          <StatusCard
            class="mb-3"
            title="Employment Status"
            :status="user.availability_readable"
            :options="employmentStatuses"
            @changed="updateAvailability"
          />
          <ProgressStatus
            amount="0.00 USD"
            description="Amount worked for"
            progress="0.00%"
          />
        </div>
      </div>
      <div class="mb-3">
        <HiringCard />
      </div>
    </div>
  </div>
</template>
<script>
import WorkSummary from "../../components/dashboard/WorkSummary.vue";
import UpdateSkills from "../../components/dashboard/UpdateSkills.vue";
import StatusCard from "../../components/dashboard/StatusCard.vue";
import ProgressStatus from "../../components/dashboard/ProgressStatus.vue";
import HiringCard from "../../components/dashboard/HiringCard.vue";

import { mapState, mapActions } from "vuex";

export default {
  components: {
    WorkSummary,
    UpdateSkills,
    StatusCard,
    ProgressStatus,
    HiringCard,
  },
  data() {
    return {
      employmentStatuses: [
        {
          name: "Open for Opportunity (Part time)",
          value: 0,
        },
        {
          name: "Open for Opportunity (Full time)",
          value: 1,
        },
        {
          name: "Employed Elsewhere",
          value: 2,
        },
        {
          name: "Learning",
          value: 3,
        },
      ],
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    ...mapActions({
      updateAvailability: "auth/updateAvailability",
    }),
  },
};
</script>
<style lang="scss" scoped>
.total {
  background-color: $body-bg;
  border: 1px solid #d6dde4;
  border-radius: 10px;
}

.status-cards {
  width: 34%;
  @include media-breakpoint-down(lg) {
    width: 48%;
  }
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}
.upskill-card {
  width: 64%;
  @include media-breakpoint-down(lg) {
    width: 50%;
  }
  @include media-breakpoint-down(md) {
    min-width: 100%;
  }
}
</style>
