<template>
  <div class="work-summary d-flex px-3 py-3">
    <div class="work-summary__icon d-flex justify-content-center align-items-center">
      <EarningIcon />
    </div>
    <div class="work-summary__info ml-3">
      <h5 class="work-summary__value">
        <strong>{{ value }}</strong>
      </h5>
      <span class="work-summary__title mt-1 d-block">{{ title }}</span>
    </div>
  </div>
</template>
<script>
import EarningIcon from "../svg/EarningIcon.vue";
export default {
  name: "WorkSummary",
  components: {
    EarningIcon,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss" scoped>
.work-summary {
  background-color: $blue-tint-5;
  border-radius: $size-10 / 2;
  width: 48%;
  min-width: 220px;
  @include media-breakpoint-down(md) {
    width: 100%;
    min-width: 180px;
  }
  &__icon {
    background-color: $dark-blue;
    border-radius: 5px;
    width: 71px;
    height: 60px;
    @include media-breakpoint-down(sm) {
      width: 36px;
      height: 36px;
      min-width: 36px;
      min-height: 36px;
    }
  }
  &__value {
    font-size: 1.375rem;
    @include media-breakpoint-down(sm) {
      font-size: 1.125rem;
    }
  }
  &__title {
    font-size: 0.875rem;
    @include add-ellipsis;
  }
}
</style>
